<template>
  <div>
    <div class="email-template">
      <div class="row">
        <div class="col-md-3" v-if="temp_.ut_content">
          <div
            class="nav flex-column nav-pills"
            role="tablist"
            aria-orientation="vertical"
          >
            <a
              v-for="temp in email_templates"
              :key="temp.ut_id"
              v-on:click.stop.prevent="temp_ = temp"
              class="nav-link"
              v-bind:class="temp_.ut_id == temp.ut_id ? 'active' : ''"
              href="#"
              role="tab"
              >{{ temp.ut_title }}</a
            >
            <router-link to="/add-template" class="nav-link"
              >+ Add New Template</router-link
            >
          </div>
        </div>
        <div class="col-md-9" v-if="temp_.ut_content">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="v-pills-touch-tab"
            >
              <div class="email-boxs">
                <ckeditor
                  v-model="temp_.ut_content"
                  @ready="onEditorReady"
                  :config="editorConfig"
                ></ckeditor>
                <div class="pt-3 pb-3">
                  <span style="font-weight: 700">Variables:</span>
                  <a class="add-btn" v-on:click="addKeyword('%CUSTOMER_NAME%')"
                    >%CUSTOMER_NAME%,
                  </a>
                  <a  class="add-btn" v-on:click="addKeyword('%DEALER_NAME%')"
                    >%DEALER_NAME%,
                  </a>
                  <a class="add-btn" v-on:click="addKeyword('%VEHICLE_MAKE%')"
                    >%VEHICLE_MAKE%,
                  </a>
                  <a class="add-btn"  v-on:click="addKeyword('%VEHICLE_MODEL%')"
                    >%VEHICLE_MODEL%,
                  </a>
                  <a class="add-btn" v-on:click="addKeyword('%VEHICLE_YEAR%')"
                    >%VEHICLE_YEAR%,
                  </a>
                  <a  class="add-btn" v-on:click="addKeyword('%VEHICLE_TRIM%')"
                    >%VEHICLE_TRIM%</a
                  >
                  <a  class="add-btn" v-on:click="addKeyword('%SALES_PERSON%')"
                    >%SALES_PERSON%</a
                  >
                  <a class="add-btn"  v-on:click="addKeyword('%BDC%')">%BDC%</a>
                  <a  class="add-btn" v-on:click="addKeyword('%SALES_PERSON_EMAIL_SIGNATURE%')"
                    >%SALES_PERSON_EMAIL_SIGNATURE%</a
                  >
                </div>
                <div class="modal-footer email-footer">
                  <a
                    href="#"
                    v-on:click.stop.prevent="deleteTemplate()"
                    class="add-btn"
                  >
                    Delete</a
                  >
                  <a
                    href="#"
                    v-on:click.stop.prevent="updateTemplate()"
                    class="rem-btn"
                  >
                    Save</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-group col-12" v-if="temp_.ut_content">
                    <label for="title" style="font-weight:700">Variables: </label>
                    <div style="width:100%;font-size: 14px;">%CUSTOMER_NAME%, %DEALER_NAME%, %VEHICLE_MAKE%, %VEHICLE_MODEL%, %VEHICLE_YEAR%, %VEHICLE_TRIM%</div>
                </div> -->
        <div v-if="!temp_.ut_content && loaded" class="no-template col-12">
          <h4>No Template Found</h4>
          <p>Please create your first email template.</p>
          <router-link to="/add-template" class="rem-btn"
            ><img
              class="img-fluid"
              src="@/assets/images/icons/Closex.svg"
              alt=""
            />
            Add New Template</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import CKEditor from "ckeditor4-vue";
export default {
  name: "Templates",
  //components: { ckeditor: CKEditor.component },
  data() {
    return {
      loaded: false,
      email_templates: [],
      temp_: { ut_content: "" },
      editorConfig: {
        toolbar: [
          [
            "Source",
            "-",
            "Bold",
            "Italic",
            "Underline",
            "Link",
            "Undo",
            "Redo",
            "Align",
          ],
        ],
        height: "350px",
        editor:''
      },
    };
  },
  created() {
    this.getTemplates();
  },
  methods: {
    onEditorReady(CKEDITOR) {
      this.editor = CKEDITOR;
    },
    addKeyword(keyword) {
      this.editor.insertText(keyword);

      //this.temp_.ut_content += keyword
    },
    getTemplates() {
      axios
        .get("get_templates/1", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.email_templates = res.data.success;
            let vm = this;
            setTimeout(() => {
              if (vm.email_templates.length > 0) {
                vm.temp_ = vm.email_templates[0];
              }
              this.loaded = true;
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateTemplate() {
      if (this.temp_.ut_id) {
        axios
          .post("update_template", this.temp_, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              alert(res.data.success);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteTemplate() {
      if (this.temp_.ut_id) {
        this.$confirm({
          title: `Are you sure?`,
          message: "You want to delete this template.",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              axios
                .post("delete_template", this.temp_, {
                  headers: {
                    Authorization: "Bearer " + this.$storage.get("auth").token,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    if (this.email_templates.length == 1) {
                      this.temp_ = { ut_content: "" };
                    }

                    this.getTemplates();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.no-template {
  height: 300px;
  text-align: center;
  padding: 80px 30px 30px;
}
.no-template h4 {
  padding-bottom: 10px;
  color: #7b7b7b;
}
.no-template p {
  padding-bottom: 30px;
  color: #9c9c9c;
}
.no-template a {
  padding-bottom: 10px;
  display: inline-block;
}
</style>
<style>
.pb-3 .add-btn{
    text-decoration: none;
    display: inline-block;
    border: 1px solid #A2A8B8;
    padding: 8px 25px;
    border-radius: 30px;
    text-transform: capitalize;
    line-height: 1;
    color: #6D778E;
    font-size: 14px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-weight: normal;
    align-items: center;
    padding: 8px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
}
</style>